<template>
  <v-card class="plans">
    <v-card-title>
        <h2>{{ title }}</h2>
        <v-spacer />
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
        ></v-text-field>
    </v-card-title>
    <v-data-table
    :headers="planHeaders"
    :items="plans"
    class="elevation-1"
    :loading="loading"
    loading-text="Loading Plans..."
    :items-per-page="50"
    :search="search"
    :no-data-text="noPlans"
    @click:row="routeTo"
    sort-by="createdAt"
    sort-desc=""
  >
  <template
        v-slot:item.createdAt="{ item }"
    >
        {{ formatDate(item.createdAt) }}
    </template>
  </v-data-table>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import dayjs from "dayjs"
export default {
  name: 'Plans',
  data() {
      return {
          search: "",
          loading: false,
          dataCheck: false,
          title: "Plans",
          options: {},
          planHeaders: [
            {
                text: "Created",
                value: "createdAt"
            },
            {
                text: "Name",
                value: "name"
            },
            {
                text: "Customer",
                value: "customer.crmName"
            },
            {
                text: "Rep",
                value: "owner.email"
            },
        ]
      }
  },
  async created() {
    if (this.token) {
        if (this.isAdmin) {
           await this.$store.dispatch("plan/getPlans")
        }
        else {
            this.$store.dispatch("plan/plansByDealer", this.user.dealerId)
        }
        
    }
  },
  watch: {
    async token(val) {
        if (this.isAdmin) {
           await this.$store.dispatch("plan/getPlans")
        }
         else {
            this.$store.dispatch("plan/plansByDealer", this.user.dealerId)
        }
    }
  },
  computed: {
    ...mapState("dealer", ["dealerPlans"]),
    ...mapState("plan", ["plans"]),
    ...mapState("user", ["token", "user"]),
    ...mapGetters("user", ["isRep", "isAdmin"]),
    noPlans() {
        if (this.dataCheck) {
            return "No Plans Found"
        }
        else {
            return "Loading Plans..."
        }
    }
  },
  methods: {
      formatDate(val) {
        return dayjs(val).format('MM/DD/YYYY h:mm a')
      },
      routeTo(item) {
        this.$router.push(`/plans/${item.id}`)
      }
  }
}
</script>

<style lang="scss" scoped>
    .plans {
        ::v-deep  tr {
            cursor: pointer;
        }
    }
</style>